<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Turnos</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Administrativo</li>
                  <li class="breadcrumb-item active">General</li>
                  <li class="breadcrumb-item active">Turnos</li>
                  <li class="breadcrumb-item active">
                    Formulario {{ accion }}
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2">
                <div class="col-6 pt-2 pb-2">
                  <h5 class="mb-0">Formulario {{ accion }} Turnos</h5>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="row">
                        <div class="col-md-4">
                          <div class="form-group">
                            <label for="nombre">Nombre</label>
                            <input
                              type="text"
                              :disabled="opcion == 1"
                              class="form-control form-control-sm"
                              id="nombre"
                              v-model="form.nombre"
                              :class="
                                $v.form.nombre.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid'
                              "
                            />
                          </div>
                        </div>
                        <table
                          id="users"
                          class="table table-bordered table-hover table-sm"
                        >
                          <thead>
                            <th>Hora</th>
                            <th
                              v-for="(dias, index) in listasForms.dias_semana"
                              :key="index.id"
                            >
                              <label for="datos_dia">
                                <input
                                  type="checkbox"
                                  :disabled="opcion == 1"
                                  id="datos_dia"
                                  v-model="datos_dia[index]"
                                />
                                <i class="form-icon"></i>
                              </label>
                              {{ dias.descripcion }}
                            </th>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Cantidad</td>
                              <td
                                v-for="(horaC,
                                index) in listasForms.dias_semana"
                                :key="index.id"
                              >
                                <input
                                  type="number"
                                  min="0"
                                  max="24"
                                  class="form-control form-control-sm"
                                  id="datos_horaC"
                                  v-model="datos_horaC[index]"
                                  :disabled="
                                    datos_dia[index] == 0 || opcion == 1
                                  "
                                  :class="
                                    !datos_horaC[index]
                                      ? 'is-invalid'
                                      : 'is-valid'
                                  "
                                />
                                <!-- 3012788629 -->
                              </td>
                            </tr>
                            <tr>
                              <td>Inicio</td>
                              <td
                                v-for="(horaI,
                                index) in listasForms.dias_semana"
                                :key="index.id"
                              >
                                <input
                                  type="text"
                                  id="datos_horaI"
                                  class="form-control form-control-sm"
                                  v-model="datos_horaI[index]"
                                  :disabled="
                                    datos_dia[index] == 0 || opcion == 1
                                  "
                                  v-mask="{
                                    mask: '99:99:99',
                                    hourFormat: '24',
                                  }"
                                  @change="validar_rango(index)"
                                  :class="
                                    !datos_horaI[index]
                                      ? 'is-invalid'
                                      : 'is-valid'
                                  "
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Fin</td>
                              <td
                                v-for="(dato_horaF,
                                index) in listasForms.dias_semana"
                                :key="index.id"
                              >
                                <input
                                  type="text"
                                  id="datos_horaF"
                                  class="form-control form-control-sm"
                                  v-model="datos_horaF[index]"
                                  :disabled="
                                    datos_dia[index] == 0 || opcion == 1
                                  "
                                  v-mask="{
                                    mask: '99:99:99',
                                    hourFormat: '24',
                                  }"
                                  @change="validar_rango(index)"
                                  :class="
                                    !datos_horaF[index]
                                      ? 'is-invalid'
                                      : 'is-valid'
                                  "
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <div class="col-md-12">
                          <TurnoLinea v-if="form.id != null" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <div class="row">
                  <button
                    class="btn bg-primary col-md-1"
                    v-show="!$v.form.$invalid"
                    @click="save()"
                    :disabled="opcion == 1"
                  >
                    <i class="fas fa-paper-plane"></i><br />Guardar
                  </button>

                  <button class="btn bg-secondary col-md-1" @click="back()">
                    <i class="fas fa-reply"></i><br />Volver
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import TurnoLinea from "./TurnoLinea";
import {
  required,
  minLength,
  maxLength,
  helpers,
} from "vuelidate/lib/validators";
import Loading from "../../../../components/Loading";
const timeFormat = helpers.regex(
  "timeFormat",
  /^([0-1]?[0-9]|2[0-3]):([0-5][0-9])(:[0-5][0-9])?$/
);

export default {
  name: "TurnosForm",
  components: {
    Loading,
    TurnoLinea,
  },
  data() {
    return {
      cargando: false,
      form: {},
      bandera_horas: false,
      bandera_dias: false,
      datos_dia: ["", "", "", "", "", "", ""],
      datos_horaI: ["", "", "", "", "", "", ""],
      datos_horaF: ["", "", "", "", "", "", ""],
      datos_horaC: [],
      datos_id: [],
      miniLogo: null,
      accion: "",
      opcion: null,
      metodo: "",
      listasForms: {
        dias_semana: [],
        cargando: [],
      },
    };
  },

  validations: {
    form: {
      nombre: {
        required,
        // minLength: minLength(1),
        // maxLength: maxLength(20),
      },
      datos_horaI: {
        index: {
          timeFormat,
          // min: minLength("00:00"),
          // max: max("23:59"),
        },
      },
    },
  },

  methods: {
    save() {
      if (!this.$v.form.$invalid) {
        this.cargando = true;
        axios({
          method: this.metodo,
          url: "/api/admin/turnos",
          data: {
            form: this.form,
            dia: this.datos_dia,
            horaI: this.datos_horaI,
            horaF: this.datos_horaF,
            horaC: this.datos_horaC,
            turno_id: this.datos_id,
          },
        })
          .then((response) => {
            this.$route.params.accion = "Editar";
            this.$route.params.data_edit = response.data;
            this.init();
            this.$swal({
              icon: "success",
              title: "Se guardo el turno correctamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.cargando = false;
          })
          .catch((e) => {
            this.cargando = false;
            if (e.response.status == 500) {
              this.error = e.response.data.message;
              if (this.error.includes("1062 Duplicate entry")) {
                this.$swal({
                  icon: "error",
                  title: "El turno ya se encuentra creada en la base de datos",
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                });
              } else {
                this.$swal({
                  icon: "error",
                  title: "Ha ocurrido un error, intentelo nuevamente",
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                });
              }
            } else {
              this.$swal({
                icon: "error",
                title:
                  "Ha ocurrido un error, por favor intente la acción nuevamente.",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            }
            this.cargando = false;
          });
      }
    },

    init() {
      this.accion = this.$route.params.accion;
      if (this.$route.params.accion == "Editar") {
        this.form = this.$route.params.data_edit;
        this.metodo = "PUT";
        this.opcion = this.$route.params.opcion;
        this.buildArrays();
      } else {
        this.metodo = "POST";
        this.form.estado = 2;
      }
    },

    buildArrays() {
      if (this.form.dia_turno) {
        for (let i = 0; i < this.form.dia_turno.length; i++) {
          let dia = this.form.dia_turno[i].dia_semana - 1;
          this.datos_dia[dia] = this.form.dia_turno[i].dia_semana
            ? true
            : false;
          this.datos_id[dia] = this.form.dia_turno[i].id;
          this.datos_horaC[dia] = this.form.dia_turno[i].cant_horas;
          this.datos_horaI[dia] = this.form.dia_turno[i].hora_inicial;
          this.datos_horaF[dia] = this.form.dia_turno[i].hora_final;
          this.validar_rango(dia);
        }
      }
    },

    async validar_rango(index) {
      if (this.datos_horaI[index] && this.datos_horaF[index]) {
        let hora1 = this.datos_horaI[index].split(":"),
          hora2 = this.datos_horaF[index].split(":"),
          t1 = new Date(),
          t2 = new Date();

        t1.setHours(hora1[0], hora1[1]);
        t2.setHours(hora2[0], hora2[1]);

        if (this.datos_horaC[index] == 24) {
          t2.setHours(t2.getHours() + 1);
        } else {
          t2.setHours(t2.getHours() - this.datos_horaC[index]);
        }

        if (t1.getHours() == t2.getHours()) {
          return true;
        } else {
          let a = null;
          this.datos_horaF[index] = a;
          await this.$swal({
            icon: "error",
            title: `La hora inicial y final no concuerdan con la cantidad de horas...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          return false;
        }
      }
    },

    async getDiasSemana() {
      await axios.get("/api/lista/90").then((response) => {
        this.listasForms.dias_semana = response.data;
      });
    },

    async getEstados() {
      await axios.get("/api/lista/1").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    back() {
      return this.$router.replace("/Admin/Turnos");
    },
  },

  async mounted() {
    this.cargando = true;
    await this.getDiasSemana();
    await this.getEstados();
    await this.init();
    this.cargando = false;
  },
};
</script>
